<template>
  <v-card>
      <v-card-title>{{ $t('booking.information') }}<v-spacer/><v-btn icon @click="$emit('onClose')"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
      <v-card-text>
          <v-list subheader>
              <v-subheader >{{ $t('booking.diagnosis') }}</v-subheader>
              <v-list-item v-for="(item,index) in diagItems" :key="index">
                  <v-list-item-content>
                      <v-list-item-title>{{item.description_th}}</v-list-item-title>
                      <v-list-item-subtitle>{{item.description}}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                      <!-- <v-list-item-action-text>{{item.code_diag}}</v-list-item-action-text> -->
                  </v-list-item-action>
              </v-list-item>
          </v-list>
          <v-divider/>
          <v-list subheader>
              <v-subheader>{{$t('booking.drug')}}</v-subheader>
              <v-list-item v-for="(item,index) in labelItems" :key="index">
                  <v-list-item-content>
                      <v-list-item-title>{{item.Generic_name}}</v-list-item-title>
                      <template v-if="$i18n.locale === 'th'">
                        <v-list-item-subtitle class="text-wrap" >{{item.instruction_text}}</v-list-item-subtitle>
                        <v-list-item-subtitle class="text-wrap">{{item.using_code2}}</v-list-item-subtitle>
                      </template>
                      <template v-else>
                        <v-list-item-subtitle class="text-wrap">{{item.instruction_eng}}</v-list-item-subtitle>
                        <v-list-item-subtitle class="text-wrap">{{item.instruction_jap}}</v-list-item-subtitle>
                        <v-list-item-subtitle class="text-wrap">{{item.info_eng}}</v-list-item-subtitle>
                        <v-list-item-subtitle class="text-wrap">{{item.info_jap}}</v-list-item-subtitle>

                      </template>
                      <v-list-item-subtitle>{{$t('patient.expired')}} {{item.exp_date}}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
          </v-list>
      </v-card-text>
  </v-card>
</template>

<script>
export default {
    props: {
        diagItems: {
            type: Array
        },
        labelItems: {
            type: Array
        }
    },

}
</script>

<style>

</style>