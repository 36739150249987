<template>
  <v-main class="natural lighten-3">
    <v-container fluid >
      <v-row class="pt-8" >
       <v-col cols="12">
          <label class="text-title-3">{{ $t('booking.lastedAppoint') }}</label>
       </v-col>
        <v-col cols="12" v-for="(item,index) in visits" :key="index">
            <BookingHistoryCard v-bind="item" @edit="editVisit(item)" @onDelete="onDeleteConfirm(item)" @view="viewVisit(item)"/>
        </v-col>
      </v-row>
    </v-container>
     <v-dialog v-model="confirmDialog" persistent>
        <ConfirmCard :title="$t('booking.cancelTitle')" :subtitle="$t('booking.cancelSubTitle')" @onClose="confirmDialog=false" @onConfirm="cancelVisit"/>
      </v-dialog>
      <v-dialog v-model="visitDialog" persistent scrollable>
        <BookingVisitCard :diagItems="diagItems" :labelItems="labelItems" @onClose="visitDialog=false"/>
      </v-dialog>
     <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-main>
</template>

<script>
import BookingHistoryCard from "@/components/booking/BookingHistoryCard";
import BookingVisitCard from "@/components/booking/BookingVisitCard";
// import { postCMSVisits } from "@/api/"
import { getPatients, postShifts } from "@/api/cms"

import { mapFields } from "vuex-map-fields";
import ConfirmCard from "@/components/common/ConfirmDeleteCard";
// import { cloneDeep } from 'lodash'
export default {
  components: {
    BookingHistoryCard,
    BookingVisitCard,
    ConfirmCard
  },
  data() {
    return {
      loading: false,
      confirmDialog :false,
      visitDialog: false,
      currentItem: null,
      items: [
        {title: 'นัดหมาย'}
      ],
      visits: [],
      diagItems: [],
      labelItems: []
    }
  },
  computed: {
    ...mapFields(['visit'])
  },
  methods: {
    async viewVisit (item) {
        // this.loading =true
        try {
          // const site = this.$offlineStorage.get("site");
          this.diagItems = item.diag
          this.labelItems = item.labels
          // const diag = await getCMSDiag({visitId: item.visit_id,rid: site.profile.rid})
          // if (diag.data.code === 1) {
          //   this.diagItems = diag.data.result
          // }else{
          //   this.diagItems = []
          // }
          // const label = await getCMSLabel({visitId: item.visit_id,groupId: site.groupId})
          // if (label.data.code === 1) {
          //   this.labelItems = label.data.result
          // }else{
          //    this.labelItems = []
          // }
          this.visitDialog = true
        }catch (error) {
          console.log(error)
        }finally {
         //  this.loading = false
        }
    },
    cancelVisit () {
      this.confirmDialog =false
      this.loading = true
      postShifts({
          ot_id: '',
          cc: '',
          is_tele: false,
          visitId: this.currentItem.visitId
        }, () => {
          this.loading = false
          this.$router.replace({name: 'appointmentcancel', params: { userFullname: this.currentItem.user_fullname, perDate:  this.currentItem.ot_st + ' ' + this.currentItem.start_time,department: this.currentItem.user_group_thai}})
          // this.fetchData()
        }, error=> {
          console.log(error)
          this.loading = false
        })
      // let data = cloneDeep(this.currentItem)
      // data.per_status = 'C'
      // this.loading =true
      // postCMSVisits(data,message=> {
      //   if(message.data.code === 1) {
      //      this.loading =false
      //      this.fetchData()
      //   }
      // })
    },
    onDeleteConfirm(item){
      this.currentItem = item
      this.confirmDialog =true
    },
    editVisit(item) {
      this.visit = item
      this.$router.replace({name: 'makeappointment'})
    },
    async fetchData() {
      try {
        // const site = this.$offlineStorage.get("site");
        this.loading = true
        let patient = await getPatients()
        if(patient.data) {
          this.visits= patient.data.visits
        }
        // await getCMSPatient({groupId: site.groupId,keyword: site.profile.patient_id})
      
        this.visit = null
        this.loading = false
      }catch (error) {
         console.log(error)
         this.loading = false
      }
    }
  },
   mounted () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
   }
};
</script>

<style lang="scss" scoped>
.v-sheet.v-card {
  border-radius: 6px;
}
.v-btn {
  border-radius: 6px;
}
</style>