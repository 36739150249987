<template>
  <v-card>
    <v-card-text>
      <v-row no-gutters>
      
        <v-col cols="11">
           <div class="text-subtitle-1 natural--text text--darken-4">
             VN {{$attrs.visitId  }} {{$t('booking.status') }}  {{ $attrs.per_status }}
          </div>
          <div class="text-h6 natural--text text--darken-4">
             {{ $t('booking.date') }} {{ $dayjs($attrs.per_date,'dd/MM/YYYY HH:mm').format('DD MMMM YYYY เวลา HH:mm')  }}
          </div>
          <div class="text-h6 natural--text text--darken-3">
          {{ $t('booking.doctor') }} {{$attrs.user_fullname}}
          </div>
           <div class="text-h6 natural--text text--darken-3">
          {{ $t('booking.department') }} {{$attrs.user_group_thai}}
          </div>
        </v-col>
          <v-col cols="1">
            <v-btn icon color="red" :disabled="!($attrs.per_status === '0' || $attrs.per_status === 'นัด/Booking')" @click="$emit('onDelete')"><v-icon>mdi-delete</v-icon></v-btn>
        </v-col>
      </v-row>
    </v-card-text>
     <v-card-actions  v-if="$attrs.per_status !== 'นัด/Booking'">
      <v-btn color="primary" outlined block @click="$emit('view')"
        ><v-icon>mdi-history</v-icon>{{ $t('booking.information') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  props: {
    name: {
      type: String,
      default: "ไม่มีข้อมูล",
    },
    formTypeName: {
      type: String,
      default: "ไม่มีข้อมูล",
    },
    cid: {
      type: String,
      default: "",
    },
    timestamp: {
      type: String,
      default: "",
    },
    mode: {
      type: String,
      default: "waiting",
    },
  },
};
</script>

<style lang="scss" scoped>
.v-sheet.v-card {
  border-radius: 6px;
}
.v-btn {
  border-radius: 6px;
}
</style>