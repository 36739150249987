import { render, staticRenderFns } from "./BookingHistory.vue?vue&type=template&id=cbe2de50&scoped=true&"
import script from "./BookingHistory.vue?vue&type=script&lang=js&"
export * from "./BookingHistory.vue?vue&type=script&lang=js&"
import style0 from "./BookingHistory.vue?vue&type=style&index=0&id=cbe2de50&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbe2de50",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VMain } from 'vuetify/lib/components/VMain';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VDialog,VMain,VOverlay,VProgressCircular,VRow})
